export const getMRerortSpaCasinoMapData = () => {
    return [
        {
            mapBg: require('@/assets/maps/bg-m-route.jpg'),
            mapLine: 'MResortSpaCasinoPath',
            venueBg:  require('@/assets/maps/m.png'),
            venue: 'M Resort Spa Casino',
            distance: '10.4',
            singleLineVenue: false,
            threeLineVenue: false
        }
    ]
}
